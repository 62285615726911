import React, {useState} from "react";
import "../styles/coopwork.css"

//this module is for getting ip address of visitor by async/await
async function Recordip(){
    console.log("welcome...")
    const url="https://zhangxiaoda.club/welcome-coop.php"
    const res=await fetch(url, {
        method:"GET",
        mode : 'no-cors',
        cache: "no-cache",
        headers: { "Content-Type": "text/html"},
        })
}
Recordip()
/* //this module is for getting ip address of visitor
fetch(url, {
    method:"GET",
    mode : 'no-cors',
    cache: "no-cache",
    headers: {
        "Content-Type": "text/html",
    }
})//.then((response)=>response.text()).then((data)=>console.log("data="+data))
*/
const fontStyle={
    s1:{  color:'#6699FF',
          fontWeight:'500',
          fontStyle: 'italic'
        },
    s2:{  fontWeight:'500',
    }
}
export default function Coopwork(){ 
    const [itemFlag, setItemFlag]=useState([false,false,false,false])
    function handleClick( clickItem: number){
        console.log("click-item="+clickItem)
        for(var i=0; i<4; i++){
            if(clickItem===i) {
                itemFlag[i]=!itemFlag[i]
            }
            else itemFlag[i]=false
            console.log("flag["+i+"]=" +itemFlag[i])
        }
        setItemFlag([itemFlag[0],itemFlag[1],itemFlag[2],itemFlag[3]])

    

    }
 
    return(
    <>
        <div className="main1Field ">
        <ul className="ul_content">
        <li className="title">IT Technician, Texada Software – Mississauga, ON  (May –  August 2024)
        <p><i>--2nd Co-operative Work Term  </i> </p>
        <div className={itemFlag[0]? "show" : "hide"} >
        <div className="space"> 
      
<p>I worked full-time as an IT Technician at Texada Software for 4 months during my second co-op, gaining significant technical skills and expanding my understanding of IT support in a professional environment. I gained experience with Level 1 and Level 2 IT support, and even expanded into Level 3 IT support by assisting the senior cloud architect in constructing our cloud environment and helping the security director optimize our company's <span style={fontStyle.s1}>Jira</span> spaces and project configurations. This role strengthened my problem-solving abilities, attention to detail, and communication skills, particularly in the context of providing technical assistance to staff and troubleshooting complex issues.</p><br />
<p>As part of the Information Security team, I was responsible for a range of IT support tasks, including troubleshooting hardware, software, and network issues, which allowed me to hone my technical expertise in both Windows and Mac environments. I also handled user account management, utilizing tools like <span style={fontStyle.s1}>JumpCloud and Active Directory</span> to provision and maintain accounts, ensuring secure access permissions. Additionally, I provided onboarding support for new employees, assisting with the setup and configuration of their IT equipment, and offering training to ensure smooth transitions.</p><br />
<p>A notable experience during my time at Texada involved a senior employee who reported that her computer was running slow. Upon investigation, I discovered 50 gigabytes of files stored in her Recycle Bin. After emptying it, her machine’s performance improved significantly. However, half an hour later, she called back in a panic, claiming that all her important files had disappeared. To my surprise, she had been using the Recycle Bin to store years of critical documents because she found it easily accessible from her desktop. My colleagues and I quickly worked to recover the files from our Google Drive backups, and while we successfully restored most of them, not all were up to date. </p><br />
<p>This incident, while amusing in hindsight, was a valuable lesson in computer ethics and user understanding. It highlighted the importance of clear communication and empathy in IT support, especially when dealing with non-technical users. It taught me that <span style={fontStyle.s2}>beyond technical skills, patience and clear explanations are crucial</span> in ensuring everyone can use technology effectively and safely.</p><br />
<p>One of the most challenging moments during this co-op was dealing with a global CrowdStrike outage, during which I was the main point of contact responsible for ensuring that all laptops within the company were up and running. Under immense pressure, I not only developed technical expertise in resolving complex bugs but also learned how to organize and prioritize tasks effectively during urgent incidents. I created a spreadsheet to track employees facing login issues, putting them in a queue to manage their needs efficiently. I prioritized solving the issues of employees who were physically present in the office, allowing me to address their problems face-to-face and create successful examples. After a series of successful fixes, I developed a concise yet clear documentation of the process so that employees could resolve the issue on their own if needed. I ensured that the documentation was easily understandable for people from all technical backgrounds.</p><br />
<p>This experience taught me a critical lesson about security compliance and IT ethics. In urgent situations like this, I learned that <span style={fontStyle.s2}>correctness and security are more important than speed</span>. Rushing under pressure can lead to mistakes, so I made it a priority to remain calm and focused on delivering accurate and secure solutions, even when time was a factor.</p><br />
<p>Additionally, I played a key role in improving the efficiency of our ticketing system through <span style={fontStyle.s1}>Jira</span>. I worked closely with users to document and track support requests, ensuring timely resolution and escalating issues when necessary. This experience allowed me to become proficient in <span style={fontStyle.s1}>ITSM</span> workflows and better understand how to prioritize tasks effectively.</p><br />
<p>Moreover, I was involved in developing and maintaining documentation for internal IT processes, which improved team efficiency and provided clear guidelines for future troubleshooting. I also supported the team in managing IT inventory, ensuring equipment was properly tracked and maintained.</p><br />
<p>According to the evaluation report from my supervisor, my best-performed areas were <span style={fontStyle.s1}>Adaptability, Technical Writing, Analytical Thinking, and Troubleshooting Ability</span>. Specifically:</p>

            <ul>
                <li>Quick to learn new software, tools, or procedures with passion, and effectively apply them to ongoing projects.

                </li>
                <li>Achieved excellent results in the documentation of procedures and processes, delivering clear and concise documentation that effectively communicated complex IT concepts to users from various backgrounds.
                </li>
                <li>Demonstrated strong analytical thinking and troubleshooting skills by efficiently diagnosing and resolving technical issues, often under pressure, while maintaining a focus on accuracy and security compliance.
                </li>   
            </ul>   
           
    
        </div>
        </div>
        <div  className={ itemFlag[0] ? "activeClass" : "inactiveClass"} onClick={()=>handleClick(0)}>  </div>
        </li>

        <li className="title">CS Grad Office Assistant, University of Waterloo – Waterloo, ON   (September-December, 2023)
        <p><i>--1st Co-operative Work Term </i> </p>
        
        <div className={itemFlag[1]? "show" : "hide"} >
        <div className="space">
            <p>
                I worked full-time as the CS Grad Office Assistant at UWaterloo for 4 months as my first co-op. I experienced a huge growth in my way of thinking and gained some invaluable lessons from working in this busy and dynamic environment.
            </p>
            
            <i className="keyTitle">Put improving efficiency as the first priority at work.</i>
            <p>I value efficiency as the most important factor in an office, especially in the CS Department where over a thousand current students, faculties, and prospective applicants have various kinds of issues that need our assistance. Therefore, I took the initiative to optimize the workflow in our department by proposing the following ideas and suggestions:
            </p>
            <ul>
                <li>Using proper keywords, such as Action Required before [deadline], in the email subjects to indicate the importance and priority, and mention the person who needs to pay attention if in a group email.
                </li>
                <li>Using Vault links to gather requested forms instead of email replying.
                </li>
                <li>Suggesting to use Excel features such as VLOOKUP or PivotTable in some cases to save time and avoid mistakes. <i>I got more expertise with Excel and gained accomplishment from helping colleagues who were not familiar with the features.</i>
                </li>   
            </ul>       
        </div>
  
        <div className="space">
            <i className="keyTitle">Good time management skills help me arrange multiple tasks.</i>
            <p>I developed my time management skills furthermore during this work term. When I managed multiple tasks required by different students, I put reminders on my Outlook Calendar so that I was able to keep following up with students and professors, keep track of multiple tasks and get them done on time.</p>
        </div>
        <div className="space">
            <i className="keyTitle">Real working activities enriched me in professional organization and communication. </i>
            <ul>
                <li>I was responsible for our department newsletter which is sent out to all students and faculties every one or two weeks. I created more creative content by using a platform called Mailchimp. I also made a reference sheet and instruction document for other people in this position later to refer to. 
                </li>
                <li>An important role of the CS Grad Office is hosting events, such as the CS Department Annual BBQ, David Cheriton Scholarship Symposium, and Graduate Programs Information Session. As the person in charge of the BBQ, I gained hands-on experience in every step of hosting a successful event, including marketing, creating and sending tickets, preparing the event, check-in, issue targeting and solving, cooperation between different departments, and post-event summaries and questionnaires. During the Graduate Programs Information session, I answered questions about CS Master's and PhD programs based on my knowledge and experience working in the CS department. I helped prospective applicants with their applications and cleared their puzzles and uncertainty about their future careers. Even though the people who came to me were mostly upper-year students, I still offered my opinion and valuable suggestions to them.
                </li>
            </ul>
        </div>
        <div className="space">
            <i className="keyTitle">The satisfactory co-op evaluation truly reflects my performance in the first work term.</i>
            <p>I gained a Very Good performance rating from my supervisor. To be more specific, my supervisor wrote in the performance evaluation that I demonstrated strong performance in the following areas:
            </p>
            <ul>
                <li>Learning job duties and work processes</li>
                <li>Analyzing problems and evaluating alternative solutions</li>
                <li>Engaging in work with curiosity; asking questions to understand more than the work assigned</li>
                <li>Adapting to changing priorities and circumstances</li>
                <li>Responding well to direction and incorporating feedback on performance</li>
                <li>Writing clearly and effectively</li>
            </ul>
            <p>My supervisor identified my top three areas of strength are <span style={{ fontWeight:600}}>“communication, critical thinking, and willingness to help”</span>. As she pointed out:
            <span style={{ color: '#6699FF'}}><i> Xiaoda learned to assess who the audience is when sending emails and adapted as applicable. His updates in team and individual meetings were now very organized and thorough. Xiaoda's critical thinking abilities have showcased through his personalizing the processes. He changed some spreadsheet trackers to enable easier access to the pertinent information. This expedited his processing of this data.</i></span></p>
            <p className="space"></p>
            <p>I’m grateful to my supervisor for giving me such great applause. I have grown a more professional mindset of taking ownership when assigned any task. <i style={{ fontWeight:600}}>I realize that when looking at an assignment as mine instead of something others assigned, gives me the initiative needed for more thorough self-management and self-assessment practices</i>. Using this mindset at work helps me come up with more creative solutions, furthermore generate more value for the department.</p>
        </div>
        </div>

        <div className={ itemFlag[1] ? "activeClass" : "inactiveClass"} onClick={()=>handleClick(1)}>  </div>
        </li>

        <li className="title">Internship in Chinasoft International (Dec 2021)
        <div className={itemFlag[3]? "show" : "hide"}>
        <div className="space">
            <p>I had another 2-week internship experience as of assistant in the Application Development department of Chinasoft International.

            Chinasoft is a leading IT service provider in China.  The team I interned with was mainly responsible for enterprise application development.  The team has abundant practice and expert talents. During the internship, I had a basic understanding of different steps in the process of enterprise application development, such as requirements workshop, solution analysis, project management, development, testing, documentation, go-live, and support.  I had opportunities to sit in on several meetings of technicians with different roles and learn how they communicate and collaborate.  I was deeply impressed by their clear thinking, strong prioritization skills, result orientation, effective collaboration, attention to detail, and commitment to tasks.  I was unfamiliar with the terminology of platforms, tools, software, and standards that they talked about in their discussion at the time, but I had a strong vision that I could use those tools well someday. 
        </p>
        </div>
        </div>
        <div  className={ itemFlag[3] ? "activeClass" : "inactiveClass"} onClick={()=>handleClick(3)}> </div>
        </li>

        <li className="title">Internship in IT Division of GE China Beijing Office (July 2019)
        <div className={itemFlag[2] ? "show" : "hide"}>
        <div className="space">
            <p>
                The IT Division where I interned is responsible for providing applications and tools required by business functions within GE.  They outsource most of their technology development to third-party service providers.  Most of the employees in this division are professional IT project managers.  I learned quite a lot from them.  My assignment leader was a senior program manager, she introduced me to comprehensive and rich project management knowledge of processes, milestones, rules, methods, responsibilities of different positions, frequently used software, timing and budget control, competence requirements of a qualified project manager, etc. In my hands-on tasks, I was particularly interested in project planning. 
            I repeatedly practiced making project plans with Microsoft Project. The plan I made was highly recognized by the internship line manager. The manager gave me an internship evaluation which can be translated as the following.

            </p>
        </div>
        
        <div className="space">
            <p style={{padding:'0rem 3rem'}}>
            <i className="tags" >
            As a junior high school graduate, Zhang Xiaoda showed great interest in his future career orientation and quick learning ability. In the position of project manager assistant, he quickly understood the importance and 
            the method of making a project plan. He efficiently learned the core concept of the software development life cycle and the main tasks contained therein. In addition, he used professional project management software to develop
             a reasonable and executable schedule for an ongoing project in real work, and successfully completed the task. His excellent performance was greatly recognized by the project manager team.  
            </i>
            </p>
        </div>
        <div className="space">
            <p>
                When I tried the planning, I gained a deeper and more reasonable understanding of time and effort forecast.  I often asked project managers questions and benefited a lot from their feedback.  I was lucky to have opportunities to learn from many professional project managers in a real workplace environment during summer vacation when I graduated from junior high school.  Their professional attitude and working methods have always guided and inspired me.
            </p>
        </div>
        </div>
        <div  className={ itemFlag[2] ? "activeClass" : "inactiveClass"} onClick={()=>handleClick(2)} > </div>
        </li>
        
    </ul>
    </div>
    
    </>
    )
}
