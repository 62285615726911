import React, {useState, useEffect} from "react";
import Gallery from './interest_gallery.tsx'

export default function Interest(){
  const [playing, setPlaying]=useState(false)
  const [curVideo, setCurVideo]=useState('video1')

  


  useEffect(()=>{   
    let video1: any
    let video2: any
    let video3: any
    video1=document.getElementById("video1")
    video2=document.getElementById("video2")
    video3=document.getElementById("video3")
    video1.width=600; video1.height=380
    video2.width=600; video2.height=380
    video3.width=600; video3.height=380

    if(playing) {
        curVideo==='video1'? video1.play() : video1.pause()
        curVideo==='video2'? video2.play() : video2.pause()
        curVideo==='video3'? video3.play() : video3.pause()
    }
    else { video1.pause(); video2.pause(); video3.pause(); }

  },[curVideo, playing])

  return(
    <>
      <div  className="main1Field">
      <ul>
        <li className="title">
            Music
            <div className="space">
            <p>When I was in high school, I joined the school's Music Box band. I was both the drummer and the bass player. Our band had several opportunities to perform for the whole school, and our performances were recognized and loved by everyone. But before I joined the band, I was an instrument novice. I had never played bass, and I didn't play the drums very well. With a strong interest and firm determination, I learned all by myself in my spare time and practiced hard. Finally, I gave a wonderful performance at the graduation ceremony to bring my two years of perseverance to a successful end.
            </p>
            </div>
            <div className="space"> 
                <p>
                    When I left home and came to Waterloo, one of my three belongings was my bass, which gave me as much attraction and pleasure as music. When I arrived at the University of Waterloo, although the learning tasks each semester were very heavy, once I was free, I would still practice the bass and perform with partners who had the same interests. At the Waterloo Chinese Spring Festival Gala, my partners and I dedicated a wonderful performance to the audience. 
                </p>
            </div>
 
            <div  className="space">
                <p>As a self-taught drummer and bass player, I'm willing to share my experience and help other beginners who want to learn music. So I made a 
                    <a href="https://www.bilibili.com/video/BV1aG4y1j7k1/?spm_id_from=333.999.0.0" target="_blank noopenner"> tutorial video</a> on how to learn instruments, music theory and improvisation by oneself.</p> 
                
            </div>
            <Gallery />
        </li>
        <li className="title top-space">
            Sports
            <p>I took part in the Toronto Half Marathon on May 7, 2023, which was a new challenge and a new start for me. Running not only makes me strong physically but also makes me have stronger willpower. Soon afterwards, I finished the TCS Toronto Waterfront Marathon on Oct. 15, 2023. I enjoy running to please me.
            </p>
        </li>
    </ul>
    
    <label style={{textAlign:'center'}} className="top-space">
    choose a video: &nbsp;&nbsp;
      <select value={curVideo} onChange={(e)=>{setCurVideo(e.target.value); setPlaying(false)}}>
        <option value='video1'>Play drum at school show (rehearsal)</option>
        <option value='video2'>Practice on improvised chord with piano</option>
        <option value='video3'>Performed at the Waterloo Chinese Spring Festival Gala (bass player)</option>
      </select>
    </label>

      <div style={{display: curVideo==='video1'? 'block':'none', textAlign:'center'}}>
        <video id="video1" controls controlsList="nodownload noremoteplayback noplaybackrate" disablePictureInPicture>
          <source src='/assets_react/drum_BNDS.mp4' type="video/mp4" />
        </video>
      </div>

      <div className="top-space" style={{display: curVideo==='video2'? 'block':'none', textAlign:'center'}}>
      <video id="video2" controls controlsList="nodownload noremoteplayback noplaybackrate" disablePictureInPicture>
          <source src='/assets_react/piano.mp4' type="video/mp4" />
        </video>
      </div>
      
      <div className="top-space" style={{display: curVideo==='video3'? 'block':'none', textAlign:'center'}}>
        <video id="video3" controls controlsList="nodownload noremoteplayback noplaybackrate" disablePictureInPicture>
          <source src='/assets_react/SpringFestival.mp4' type="video/mp4" />
        </video>
      </div>
      <div className="top-space"></div>
    </div>
    </>
  )
}